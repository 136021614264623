import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { addLoading, removeLoading } from '../store/loader/loader.action';
import { Store } from '@ngrx/store';
import { PlatformService } from '../services/plateform.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(private store: Store, private platformService: PlatformService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.platformService.log("LoaderInterceptor");
        this.store.dispatch(addLoading());
        return next.handle(request).pipe(finalize(() => this.store.dispatch(removeLoading())));
    }
}
