import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InterceptorService } from '../services/interceptor.service';
import { PlatformService } from '../services/plateform.service';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    constructor(private interceptorService: InterceptorService, private platformService: PlatformService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.platformService.log("BaseUrlInterceptor");
        let googleRequest = this.interceptorService.isAGoogleRequest(request);
        if(googleRequest) {
            return next.handle(googleRequest);
        }

        let i18nRequest = this.interceptorService.isAi18nRequest(request);
        if (i18nRequest) {
            return next.handle(i18nRequest);
        }
        
        let defaultRequest = this.interceptorService.handleDefaultRequests(request);
        
        return next.handle(defaultRequest);
    }
}
