import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderLocalStorageService } from './services/order-local-storage.service';
import { loadAuth } from './store/auth/auth.actions';
import {
  loadTemplateType,
  setCreationOrder,
} from './store/order/order.actions';
import { connectWebSocket } from './store/repository/repository.actions';
import { LanguageService } from './services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from './services/plateform.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  NgcCookieConsentService,
  NgcInitializationErrorEvent,
  NgcInitializingEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';
import { SeoService } from './services/seo.service';
import { TrackingService } from './services/tracking.service';
import {
  TrackingCookieConsent,
  TrackingPageView,
} from './models/tracking.model';
import { CookieService } from './services/cookie.service';
// import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  private cookieConsent: string;

  constructor(
    private store: Store,
    private orderLocalStorage: OrderLocalStorageService,
    private platformService: PlatformService,
    public router: Router,
    private seoService: SeoService,
    private trackingService: TrackingService,
    private cookieService: CookieService,
    public translateService: TranslateService,
    public languageService: LanguageService,
    private ccService: NgcCookieConsentService) {}

  ngOnInit() {
    //this.ccService.destroy();    
    this.cookieConsent = this.cookieService.getSpecificKeyFromCookie('cookie_consent');
    this.platformService.log('app.components');


    if (this.platformService.isPlatformBrowser()) {
      this.loadScript('https://accounts.google.com/gsi/client', true, true);
      this.loadScript('https://js.stripe.com/v3/', true, false);

      this.cookieConsent == 'granted' ? this.trackingService.cookieConsentGranted() : this.trackingService.cookieConsentDefault();  
      this.initConsentBanner();
      this.trackPageView();   
    }

    this.platformService.log('app.components 2');

    this.store.dispatch(loadAuth());
    this.store.dispatch(connectWebSocket());
    this.platformService.log('app.components 3');
    if (this.platformService.isPlatformBrowser()) {
      this.store.dispatch(
        setCreationOrder({
          creationOrder: this.orderLocalStorage.getCreationOrder(),
        }),
      );
      this.store.dispatch(loadTemplateType());
    }
    this.platformService.log("app.component end");
  }

  trackPageView() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const tags = this.seoService.getSeoTags();
        let trackingDTO: TrackingPageView = {
          eventType: 'pageView',
          pageUrl: event.url,
          pageTitle: tags.title,
        };
        this.trackingService.track(trackingDTO);
      }
    });
  }

  public loadScript(url: string, asyn: boolean, defer: boolean) {
    let node = document.createElement('script');
    node.type = 'text/javascript';
    node.src = url;
    node.async = asyn;
    node.defer = defer;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  initConsentBanner() {
    // let cookie_consent =
    //   this.cookieService.getSpecificKeyFromCookie('cookie_consent');
    //console.log('cookie_consent', cookie_consent, cookie_consent!='granted');
    if (this.platformService.isPlatformBrowser()) {
      this.translateService
        .get([
          'cookie.header',
          'cookie.message',
          'cookie.dismiss',
          'cookie.allow',
          'cookie.deny',
          'cookie.link',
          'cookie.policy',
        ])
        .subscribe((data) => {
          this.configureCookieBanner(data);

          if (this.cookieService.consentToRenew()) {
            this.ccService.init(this.ccService.getConfig());            
          }
        });

      // subscribe to cookieconsent observables to react to main events
      this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
        //console.log('popupOpenSubscription');
      });

      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
        //console.log('popupCloseSubscription');
      });

      this.initializingSubscription = this.ccService.initializing$.subscribe(
        (event: NgcInitializingEvent) => {
          // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
          //console.log(`initializing: ${JSON.stringify(event)}`);
        },
      );

      this.initializedSubscription = this.ccService.initialized$.subscribe(
        () => {
          // the cookieconsent has been successfully initialized.
          // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
          //console.log(`initialized: ${JSON.stringify(event)}`);
          this.trackingService.cookieConsentDefault();
        },
      );

      this.initializationErrorSubscription =
        this.ccService.initializationError$.subscribe(
          (event: NgcInitializationErrorEvent) => {
            //console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
          },
        );

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          //console.log(`statusChangeSubscription: ${JSON.stringify(event)}`);
          let consentStatus: 'granted' | 'denied' = event.status == 'deny' ? 'denied' : 'granted';

          if (consentStatus == 'granted'){
            this.ccService.hasConsented();
            this.trackingService.cookieConsentGranted();
          } 
          this.cookieService.setCookieConsent(consentStatus);
        },
      );

      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
          //console.log('revokeChoiceSubscription');
        },
      );

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          //console.log(`noCookieLawSubscription: ${JSON.stringify(event)}`);
        },
      );
    }
  }

  configureCookieBanner(data) {
    this.ccService.getConfig().content =
      this.ccService.getConfig().content || {};
    // Override default messages with the translated ones
    this.ccService.getConfig().content.header = data['cookie.header'];
    this.ccService.getConfig().content.message = data['cookie.message'];
    this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
    this.ccService.getConfig().content.allow = data['cookie.allow'];
    this.ccService.getConfig().content.deny = data['cookie.deny'];
    this.ccService.getConfig().content.link = data['cookie.link'];
    this.ccService.getConfig().content.policy = data['cookie.policy'];
    this.ccService.getConfig().content.href = environment.seoUrl + '/cookies';
    this.ccService.destroy(); // remove previous cookie bar (with default messages)
  }

  destroyConsentBanner() {
    //unsubscribe to cookieconsent observables to prevent memory leaks
    if (this.platformService.isPlatformBrowser()) {
      this.popupOpenSubscription.unsubscribe();
      this.popupCloseSubscription.unsubscribe();
      this.initializingSubscription.unsubscribe();
      this.initializedSubscription.unsubscribe();
      this.initializationErrorSubscription.unsubscribe();
      this.statusChangeSubscription.unsubscribe();
      this.revokeChoiceSubscription.unsubscribe();
      this.noCookieLawSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.destroyConsentBanner();
  }
}
