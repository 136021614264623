import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import {delay, map, Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsAuth } from '../store/auth/auth.selectors';
import { PlatformService } from '../services/plateform.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(private store: Store, private router: Router, private platformService: PlatformService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.getAuth$();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.getAuth$();
    }

    getAuth$(): Observable<boolean> {
        this.platformService.log('getAuth');
        return this.store.select(selectIsAuth).pipe(
          delay(1000),
            map((isAuth: any) => {
              if (!isAuth) {
                    this.platformService.log('NOT AUTHENTICATED 1 !!');
                    this.router.navigate(['/']);
                    return false;
                }
                return true;
            })
        );
    }
}
